import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Wappen from '../../img/wappen.png';
import { Menu, X } from 'styled-icons/boxicons-regular/';
import { Link } from 'gatsby';
import NavList from './navList';
import Mobile from './mobile';

const Wrapper = styled.div`
`
const MobileNav = styled.nav`
    display: none;
    width: 100%;
    background: ${props => props.theme.colors.darkGray};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    @media (max-width: 980px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }
`

const Nav = styled.nav`
    background: ${props => props.theme.colors.black};
    width: 150px;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    @media (max-width: 980px) {
      display: none;
    }
`
const zoom = keyframes`
    from {
        transform: scale(0) rotate(-90deg);
    }
    to {
        transform: scale(1) rotate(0);
    }
`

const LogoWrapper = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
    animation: ${zoom} 1s ease;
    @media (max-width: 980px) {
      height: 60px;
      width: 60px;
      padding: 0;
    }
`
const Logo = styled.img`
    width: 100%;
    height: auto;
    max-width: 100px;
`
const ButtonWrap = styled.div`
    cursor: pointer;
    padding: 10px;
`

const SMenu = styled(Menu)`
    color: ${props => props.theme.colors.white};
`
const A = styled(Link)`
    text-decoration: none;
`

const MainNav = () => {

    const [showMobileNav, setShowMobileNav] = useState(false);

    return (
        <Wrapper>
            <MobileNav>
                <A to="/">
                    <LogoWrapper>
                        <Logo src={Wappen} />
                    </LogoWrapper>
                </A>
                <ButtonWrap onClick={() => setShowMobileNav(!showMobileNav)}>
                    { showMobileNav ? <X size={35} color="#fff" /> : <SMenu size={35} /> }
                </ButtonWrap>
            </MobileNav>
            { showMobileNav && <Mobile /> }
            <Nav>
                <A to="/">
                    <LogoWrapper>
                        <Logo src={Wappen} />
                    </LogoWrapper>
                </A>
                <NavList />
            </Nav>
        </Wrapper>
    )
};

export default MainNav;