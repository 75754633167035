import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Social from '../common/social';

const Wrapper = styled.ul`

`
const NavElem = styled.li`
    font-family: ${props => props.theme.fonts.heading};
    text-transform: uppercase;
    color: ${props => props.theme.colors.lightGray};
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    min-height: 65px;
    line-height: 20px;
    border-bottom: 1px solid ${props =>  props.theme.colors.gray};
    &:first-child {
        border-top: 1px solid ${props =>  props.theme.colors.gray};
    }
    &:hover {
        border-left: 8px solid ${props =>  props.theme.colors.accent};
        padding-left: 12px;
        color: ${props => props.theme.colors.white};
    }
    ${props => props.active && `
        border-left: 8px solid ${props.theme.colors.accent};
        padding-left: 12px;
        color: ${props.theme.colors.white};
    `}
    cursor: pointer;
`
const A = styled(Link)`
    text-decoration: none;
`
const Mobile = styled.div`
    @media (min-width: 980px) {
      display: none;
    }
`

const NavList = () => {

    const [pathname, setPathname] = useState('/');

    useEffect(() => {
        setPathname(window.location.pathname);
    },[setPathname]);

    return (
        <Wrapper>
            <Mobile>
            <A to="/">
                <NavElem active={pathname === '/'}>
                    Home
                </NavElem>
            </A>
            </Mobile>
            <A to="/neuigkeiten">
                <NavElem active={pathname === '/neuigkeiten'}>
                    News
                </NavElem>
            </A>
            <A to="/fitness_gymnastik">
                <NavElem active={pathname === '/fitness_gymnastik'}>
                    Fitness & Gymnastik
                </NavElem>
            </A>
            <A to="/fussball">
                <NavElem active={pathname === '/fussball'}>
                    Fußball
                </NavElem>
            </A>
            <A to="/kinderturnen">
                <NavElem active={pathname === '/kinderturnen'}>
                    Kinderturnen
                </NavElem>
            </A>
            <A to="/sport_mit_handicap">
            <NavElem active={pathname === '/sport_mit_handicap'}>
                Sport mit Handicap
            </NavElem>
            </A>
            <A to="/verein">
            <NavElem active={pathname === '/verein'}>
                Der Verein
            </NavElem>
            </A>
            <A to="/stammtisch">
            <NavElem active={pathname === '/stammtisch'}>
                Sportheim Stammtisch
            </NavElem>
            </A>
            <A to="/anfahrt">
            <NavElem active={pathname === '/anfahrt'}>
                Anfahrt & Kontakt
            </NavElem>
            </A>
            <A to="/sponsoren">
            <NavElem active={pathname === '/sponsoren' || pathname === '/sponsor_werden' || pathname === '/foerderverein'}>
                Sponsoren
            </NavElem>
            </A>
            <Social />
        </Wrapper>
    )
}

export default NavList;