import React from 'react';
import styled from 'styled-components';
import { InstagramWithCircle, FacebookWithCircle, YoutubeWithCircle } from 'styled-icons/entypo-social';

const Wrapper = styled.div`
    display: flex;
    padding: 20px 5px;
    justify-content: space-around;
    max-width: 180px;
    margin: auto;
`
const A = styled.a`
    text-decoration: none;
    color: #dedede;
    &:hover {
        color: ${props => props.theme.colors.accent};
    }
`

const Social = () => {
    return (
        <Wrapper>
            <A href="https://instagram.com/svkappel" target="_blank">
                <InstagramWithCircle size={30} />
            </A>
            <A href="https://facebook.com/sv.kappel" target="_blank">
                <FacebookWithCircle size={30} />
            </A>
            <A href="https://www.youtube.com/channel/UCpjAsu9HxDHx47arM2dObHQ" target="_blank">
                <YoutubeWithCircle size={30} />
            </A>
        </Wrapper>
    )
};

export default Social;
