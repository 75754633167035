import React from 'react';
import styled from 'styled-components';
import NavList from './navList';

const Wrapper = styled.div`
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.colors.black};
    z-index: 9999;
    overflow-y: auto;
`

const Mobile = () => {
    return (
        <Wrapper className="animate__animated animate__fadeInUp">
            <NavList />
        </Wrapper>
    )
}

export default Mobile;