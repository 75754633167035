import React from 'react';
import styled from 'styled-components';
import Wappen from '../../img/wappen.png';
import { Link } from 'gatsby';
import Social from '../common/social';

const Wrapper = styled.footer`
    padding: 20px 20px 5px 20px;
    background: ${(props) => props.theme.colors.darkGray};
`;
const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;
const Logo = styled.img`
    width: 130px;
    height: auto;
`;
const ElemWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const A = styled(Link)`
    text-decoration: underline;
    color: ${(props) => props.theme.colors.white};
`;
const P = styled.p`
    padding: 0;
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 1.4em;
`;
const I = styled(P)`
    font-weight: 400;
    font-size: 1em;
`;
const Ak = styled.a`
    margin-top: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.gray1};
`;

const Index = () => {
    return (
        <Wrapper>
            <LogoWrapper>
                <Logo src={Wappen} alt="SV Kappel Wappen" />
            </LogoWrapper>
            <Social />
            <ElemWrapper>
                <A to="/mitglied-werden">Mitglied werden</A>
            </ElemWrapper>
            <ElemWrapper>
                <P>Sportverein Kappel e.V. 1953</P>
                <I>Großtalstraße 15</I>
                <I>79117 Freiburg</I>
            </ElemWrapper>
            <ElemWrapper>
                <A to="/impressum">Impressum / Datenschutz</A>
            </ElemWrapper>
            <ElemWrapper>
                <Ak href="https://www.kiefer.media" target="_blank">
                    Design & Umsetzung: KIEFER.MEDIA
                </Ak>
            </ElemWrapper>
        </Wrapper>
    );
};

export default Index;
