import React from "react"
import styled from "styled-components"
import CookieImg from "../img/cookie.jpg"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #333;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 620px) {
    left: 10px;
  }
`
const Image = styled.img`
  width: 200px;
  height: 200px;
  @media (max-width: 480px) {
    display: none;
  }
`
const TextWrap = styled.div`
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 620px) {
    max-width: 90%;
  }
`
const Text = styled.p``

const BtnWrap = styled.div`
  display: flex;
  justify-content: space-around;
`

const Btn = styled.div`
  background: ${props => props.theme.colors.accent};
  padding: 5px 10px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.accent};
  }
`
const BtnNo = styled(Btn)`
  color: ${props => props.theme.colors.accent};
  background: none;
`

function isBrowser() {
  return typeof window !== "undefined"
}

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue)
  })

  React.useEffect(() => {
    setValue(key, value)
  }, [key, value])

  return [value, setter]
}

const Cookie = () => {
  const location = useLocation()

  if (isBrowser()) {
    initializeAndTrack(location)
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    "consentCookieHidden"
  )

  const startTracking = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true"
    setBannerHidden(true)
  }

  const noTracking = () => {
    setBannerHidden(true)
  }

  return (
    <>
      {!bannerHidden && (
        <Wrapper>
          <Image src={CookieImg} />
          <TextWrap>
            <Text>
              Dürfen wir cookies verwenden, um unsere Webseite zu verbessern?
            </Text>
            <BtnWrap>
              <Btn onClick={() => startTracking()}>Ja</Btn>
              <BtnNo onClick={() => noTracking()}>Nein</BtnNo>
            </BtnWrap>
          </TextWrap>
        </Wrapper>
      )}
    </>
  )
}

export default Cookie
