import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import MainNav from '../components/menu/mainNav';
import reset from 'styled-reset';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Seo from './seo';
import Cookie from './cookie_consent';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${(props) => props.theme.fonts.normal};
    font-size: 16px;
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
  }

  body::-webkit-scrollbar       {background-color:#fff;width:16px}
  body::-webkit-scrollbar-track {background-color:#fff}
  body::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:4px solid #fff}


  body {
    line-height: 1.5;
    letter-spacing: 0;
    height: 100%;
    width: 100%;
  }
  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
  }

  h1, h2, h3, h4, h5 {
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 600;
  }

  h1 {
      font-weight: 900;
      font-size: 2.5em;
      padding: 10px 0 20px 0;
  }
  h2 {
    font-size: 1.5em;
  }
  b {
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.heading}; 
  }
  p {
    padding: 5px 0;
  }
  .ReactModal__Content {
    inset: 0 0 0 150px !important;
  }

  ::-webkit-scrollbar       {background-color:#fff;width:10px}
  ::-webkit-scrollbar-track {background-color:#fff}
  ::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:13px;border:3px solid #fff}

  .ReactModal__Body--open {
    overflow-y: hidden;
  }
`;

const theme = {
    colors: {
        black: '#000',
        darkGray: '#2A2A2A',
        gray: '#383838',
        lightGray: '#aaaaaa',
        gray2: '#C0C0C0',
        gray1: '#EAEAEA',
        white: '#ffffff',
        accent: '#FF0028',
        accent2: '#211010',
    },
    fonts: {
        normal: 'Titillium Web',
        heading: 'Rajdhani',
        bold: 'Anonymous Pro',
        pt: 'PT Sans',
    },
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGray};
    font-family: ${(props) => props.theme.fonts.normal};
    display: flex;
    ${(props) =>
        props.modal &&
        `
      background: none;
      visibility: hidden;
    `}
`;
const Content = styled.div`
    flex: 1;
    padding-left: 150px;
    width: 100%;
    @media (max-width: 980px) {
        padding: 80px 0 0 0;
    }
`;
const Inner = styled.div`
    background: ${(props) => props.theme.colors.white};
`;
const WrapperModal = styled.div`
    position: fixed;
    top: 0;
    left: 150px;
    bottom: 0;
    right: 0;
    background: #211010;
    padding: 40px;
    overflow: auto;
    @media (max-width: 980px) {
        left: 0;
        padding: 110px 20px;
    }
`;

const SiteWrapper = ({
    children,
    modal,
    title,
    description,
    image,
    article,
}) => {
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <link
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    rel="stylesheet"
                    type="text/css"
                />
                <script
                    type="text/javascript"
                    src="https://www.fussball.de/static/layout/fbde2/egm//js/widget2.js"
                ></script>
            </Helmet>
            <Seo
                title={title}
                description={description}
                image={image}
                article={article}
            />
            <GlobalStyle />
            {modal ? (
                <WrapperModal className="animate__animated animate__fadeIn">
                    <Inner>{children}</Inner>
                </WrapperModal>
            ) : (
                <Wrapper>
                    <MainNav />
                    <Content>
                        <Inner>{children}</Inner>
                        <Footer />
                    </Content>
                    <Cookie />
                </Wrapper>
            )}
        </ThemeProvider>
    );
};

export default SiteWrapper;
